<template>
  <div>
    <GenericForm
      title="classes de materiais"
      previousRoute="classeMaterial"
      editRoute="classeMaterialEditar"
      :model="model"
      :route="classeMaterialRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_classe_material"
      :permissionsToWrite="['rw_material']"
      :permissionsToEdit="['re_material']"
      :permissionsToDelete="['rd_material']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nome*" label-for="nome">
              <b-form-input
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                name="nome"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';

export default {
  components: {
    GenericForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_classe_material: this.$route.params.id || -1,
        nome: '',
        descricao: '',
      },

      classeMaterialRoute: 'classeMaterial',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },
  },
};
</script>
